export default {
	id: "HowToBecomePartner",
	inputAriaLablel: "Email",
	header: "Сколько вы сможете заработать с Quick Resto",
	content: "Оставьте заявку и получите пример расчёта",
	button: "Получить",
	placeholder: "example@email.com",
	notification:
		"Заявка принята! Менеджер уже готовит расчёт, чтобы отправить вам на почту",
	invalidMessage:
		"Ошибка на сервере! Пожалуйста, позвоните нам: <a href='tel:88005002138'>8 800 500-21-38</a> (звонок бесплатный)",
}
