import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import SectionContainer from "../../SectionContainer"
import CheckList from '../../CheckList'

import styles from './styles.module.styl'

import sectionData from '../../../pages-data/partners/partnersOffer'


export default function SectionPartnersAward() {
  return (
          <SectionContainer className={styles.award__sectionContainer}>
            <section className={styles.award}>

              <div className={styles.award__textColumn}>

                <h2 className={styles.award__header}>
                  {sectionData.header}
                </h2>

                <p className={styles.award__description}>
                  {sectionData.description}
                </p>

                <CheckList
                  data={sectionData.features}
                  liClassName={styles.award__feature}
                  ulClassName={styles.award__featureWrapper}
                />

              </div>

              <StaticImage
                src='../../../assets/images/partnersOffer.png'
                className={styles.award__image}
                alt={sectionData.alt}
                placeholder='none'
              />

              {/*"Мобильный" чеклист*/}
              <CheckList
                data={sectionData.features}
                liClassName={styles.award__feature}
                ulClassName={`
                  ${styles.award__featureWrapper} 
                  ${styles.mobile}
                `}
              />

            </section>
          </SectionContainer>
        )
}
