import React from 'react'
import PropTypes from 'prop-types'

import { getEmailValidState } from "../../../utils/validateFunctions"
import { ctaErrorAmoOffer } from "../../../apiRequests/callToAction"
import { ACTION_STATUSES } from "../../CtaAbstractSection"

import CtaAbstractForm from "../../CtaAbstractSection"
import SectionContainer from '../../SectionContainer'
import InputIndexPage from '../../InputIndexPage'
import ButtonFilled from '../../ButtonFilled'
import ActionNotification from '../../ActionNotification'

import SquareCheck from "../../../assets/svg/icon_chekSquare.svg"
import RedCheck from "../../../assets/svg/icon__redCheck.svg"

import componentData from "../../../pages-data/common/commonData"

import styles from './styles.module.styl'
import agreementStyles from '../SectionPhoneCallRequest/styles.module.styl'


export default class SectionEmailRequest extends CtaAbstractForm {

  constructor(props) {
    super(props)
  }

  validateInput = email => {
    return getEmailValidState(email, false)
  }

  actionRequest = async email => {

    await this.props.ctaAction(email)

    try {
      this.props.gtmAction()
    }
    catch (error) {
      console.log(error)
    }
  }

  ctaErrorAmoOffer = () => {
    return ctaErrorAmoOffer('phone', this.state.inputValue)
  }

  render() {

    const sectionData = this.props.data

    const renderForm = () => (
      <div>
        <div className={styles.check__formContainer}>

          <InputIndexPage
            inputAriaLablel={sectionData.inputAriaLablel}
            className={styles.check__input}
            placeholder={sectionData.placeholder}
            invalidMessage={this.state.inputInvalidMessage}
            value={this.state.inputValue}
            changeHandler={this.inputChangeHandler}
            extraProps={{
              type: 'email',
              name: 'email'
            }}
          />

          <ButtonFilled
            colorType={'blue'}
            className={styles.check__button}
            handler={() => this.actionButtonHandler('email')}
            disabled={this.state.actionStatus === ACTION_STATUSES.SENDING}
          >
            {sectionData.button}
          </ButtonFilled>

        </div>

        {/*Согласие на обработку данных*/}
        <div className={agreementStyles.phoneCallRequest__agreementWrapper}>
          {
            this.state.agreementStatus
              ? <SquareCheck onClick={() => this.setState({
                agreementStatus: false,
              })
              }/>
              : <RedCheck onClick={() => this.setState({
                agreementStatus: true,
                agreementWarning: false
              })}/>
          }

          <p className={agreementStyles.phoneCallRequest__agreement}>
            {componentData.ctaRequestAgreement}
          </p>

          <div className=
                 {`${agreementStyles.phoneCallRequest__agreementWarning}
                   ${this.state.agreementWarning ? '' : agreementStyles.hide}`}
          >
            {componentData.requireAgreementWarning}
          </div>

        </div>
      </div>

    )

    return (
      <SectionContainer
        className={`
        ${styles.checkContainer}
        ${this.props.className ? this.props.className : ''}
        `}
      >
        <section className={styles.check}>

          <div
            id={sectionData.id}
            className={styles.check__anchor}
          />

          <h2 className={styles.check__header}>
            {sectionData.header}
          </h2>

          <p className={styles.check__text}>
            {sectionData.content}
          </p>

          {
            this.state.actionStatus === ACTION_STATUSES.NOT_SEND || this.state.actionStatus === ACTION_STATUSES.SENDING
              ? renderForm()
              : (
                <ActionNotification
                  className={styles.check__ActionNotification}
                  isError={this.state.actionStatus === ACTION_STATUSES.FAIL}
                  closeHandler={this.hideNotification}
                  text={
                    this.state.actionStatus === ACTION_STATUSES.SUCCESS
                      ? sectionData.notification
                      : sectionData.invalidMessage
                  }
                />
              )
          }


        </section>
      </SectionContainer>
    )
  }

}

SectionEmailRequest.propTypes = {
  data: PropTypes.object.isRequired, // текст (заголовок, описание и т.д.)
  ctaAction: PropTypes.func.isRequired, // основной обработчик при отправке формы
  gtmAction: PropTypes.func, // функция для генерации google-tag-manager-события
  className: PropTypes.string // доп.класс для стилизации
}
