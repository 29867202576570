import React from "react"
import { Link } from "gatsby"

import { pagesLinks } from "../common/links"

export default {
	header: "Ваш доход\n уже через полгода:\n 160 000 ₽/мес*",
	textFirst: (
		<>
			Чем больше клиентов —{"\n"} тем выше ваш процент{"\n"} каждой подписки.
			{"\n"} Все прозрачно: проверяйте{"\n"} оплату клиентов через{"\n"}{" "}
			<Link
				to={pagesLinks.login.href}
				useGatsbyLink={pagesLinks.login.useGatsbyLink}
			>
				личный кабинет.
			</Link>
		</>
	),
	textFiirst: ``,
	textSecond:
		"Начисляем 100% от оплаты\n за первые два месяца подписки\n клиента",
	remark:
		"*Средний месячный размер вознаграждения партнёра Quick Resto в 2020 году.",
	alt: "Размер вознаграждения партнеров Quick Resto",
	revenueLevels: [
		{
			partnerLevel: "Партнер",
			percentageRevenue: "30% от начислений",
			cashRevenue: "75 000 ₽*",
		},
		{
			partnerLevel: "Бизнес партнер",
			percentageRevenue: "40% от начислений",
			cashRevenue: "150 000 ₽*",
		},
		{
			partnerLevel: "Премиум партнер",
			percentageRevenue: "50% от начислений",
			cashRevenue: "450 000 ₽*",
		},
	],
}
