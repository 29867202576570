import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'
import CheckList from '../../CheckList'

import sectionData from '../../../pages-data/partners/partnersEasyFind'

import styles from './styles.module.styl'


export default function SectionPartnersEasyFind() {
  return (
        <SectionContainer className={styles.easyFind__sectionContainer}>
          <section className={styles.easyFind}>

            <h2 className={styles.easyFind__header}>
              {sectionData.header}
            </h2>

            <p className={styles.easyFind__description}>
              {sectionData.description}
            </p>

            <div className={styles.easyFind__columnsWrapper}>
              <StaticImage
                src='../../../assets/images/partnersClients.png'
                className={styles.easyFind__image}
                placeholder='none'
                alt={sectionData.alt}
              />

              <div className={styles.easyFind__textColumn}>
                <h3 className={styles.easyFind__subtitle}>
                  {sectionData.subtitle}
                </h3>

                <CheckList
                  data={sectionData.partnersList}
                  liClassName={styles.easyFind__feature}
                  ulClassName={styles.easyFind__featureBox}
                />

                <p className={styles.easyFind__remark}>
                  {sectionData.remark}
                </p>
              </div>
            </div>

          </section>
        </SectionContainer>
  )
}
