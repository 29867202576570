import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import SectionContainer from "../../SectionContainer"

import ChevronRight from '../../../assets/svg/chevronRight.svg'
import ChevronLeft from '../../../assets/svg/chevronLeft.svg'

import styles from './styles.module.styl'

import sectionData from '../../../pages-data/partners/partnersReviews'


export default function SectionPartnersReviews() {

  return (
    <StaticQuery
      query={graphql`
      query {
        savchenko: file(relativePath: { eq: "savchenko.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        habarov: file(relativePath: { eq: "habarov.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        azimov: file(relativePath: { eq: "azimov.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        radjabov: file(relativePath: { eq: "radjabov.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        
      }
      `}
      render={data => {

        // Чтобы сайтик не падал при нажатии на кнопку "Назад":
        if (!sectionData.populated) {
          sectionData.partners.forEach(
            partner => partner.img = data[partner.img].childImageSharp.gatsbyImageData
          )
          sectionData.populated = true
        }

        // По образу https://react-slick.neostack.com/docs/example/custom-arrows
        function NextArrow(props) {
          return (
            <div
              className={`${styles.partnersReviews__arrows} ${styles.rightArrow}`}
              onClick={props.onClick}
            >
              <ChevronRight />
            </div>
          )
        }

        function PrevArrow(props) {
          return (
            <div
              className={`${styles.partnersReviews__arrows} ${styles.leftArrow}`}
              onClick={props.onClick}
            >
              <ChevronLeft/>
            </div>
          )
        }

        const sliderSetting = {
          nextArrow: <NextArrow/>,
          prevArrow: <PrevArrow/>,
          className: styles.partnersReviews__slider,
          dots: true,
          dotsClass: `slick-dots ${styles.partnersReviews__dots}`
        }

        return (
          <SectionContainer className={styles.partnersReviews__sectionContainer}>
            <section className={styles.partnersReviews}>

              <h2 className={styles.partnersReviews__header}>
                {sectionData.header}
              </h2>

              <Slider {...sliderSetting}>
                {
                  sectionData.partners.map(
                    partner => (
                      <div
                        key={partner.img}
                        className={styles.partnersReviews__review}
                      >
                        <div className={styles.partnersReviews__photo}>
                          <GatsbyImage
                            image={partner.img}
                            alt={partner.alt}
                            className={styles.partnersReviews__img}
                          />

                          {/*"десктопная" ссылка*/}
                          <a
                            className={styles.partnersReviews__desktopLink}
                            href={partner.link.href}
                            target='_blank'
                          >
                            {partner.link.text}
                          </a>

                        </div>

                        <div>
                          <p className={styles.partnersReviews__title}>
                            {partner.title}
                          </p>

                          {/*"мобильная" ссылка*/}
                          <a
                            className={styles.partnersReviews__mobileLink}
                            href={partner.link.href}
                            target='_blank'
                          >
                            {partner.link.text}
                          </a>

                          {
                            partner.text.map(
                              (item, index) => (
                                <p
                                  key={index}
                                  className={`
                                    ${styles.partnersReviews__text}
                                    ${typeof item === 'string' ? styles.hide : ''}
                                  `}
                                >
                                  {item.mobile || item}
                                </p>
                              )
                            )
                          }
                        </div>
                      </div>
                    )
                  )
                }
              </Slider>

            </section>
          </SectionContainer>
        )
      }}
    />
  )
}
