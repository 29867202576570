import React from "react"
import PropTypes from "prop-types"

import Check from "../../assets/svg/icon_check.svg"
import ServiceCheck from "../../assets/svg/icon_service_check.svg"

import styles from "./styles.module.styl"

export default function CheckList(props) {
	return (
		<ul className={props.ulClassName || ""}>
			{props.data.map(item => (
				<li
					className={`
              ${styles.checkList__listItem}
              ${props.discounts ? styles.discounts : ""}
              ${props.liClassName || ""}
            `}
					key={item}
				>
					{props.service ? (
						<ServiceCheck className={styles.service} />
					) : (
						<Check />
					)}
					{item}
				</li>
			))}
		</ul>
	)
}

CheckList.propTypes = {
	data: PropTypes.array.isRequired,
	ulClassName: PropTypes.string,
	liClassName: PropTypes.string,
	service: PropTypes.bool, // нужно ли использовать особые маркеры (см. стр. "/service")
	discounts: PropTypes.bool, // нужно ли использовать особые маркеры (см. стр. "/discounts/<action>")
}
