import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import SectionContainer from '../../SectionContainer'

import styles from './styles.module.styl'

import sectionData from '../../../pages-data/partners/partnersProgramm'

export default function SectionPartnersProgramm() {
  return (
    <SectionContainer className={styles.partnersProgram__sectionContainer}>
      <section className={styles.partnersProgram}>

        <h1 className={styles.partnersProgram__header}>
          {sectionData.header}
        </h1>

        <p className={styles.partnersProgram__subtitle}>
          {sectionData.subtitle}
        </p>

        <a
          href={sectionData.link}
          className={styles.partnersProgram__button}
        >
          {sectionData.buttonText}
        </a>

        <StaticImage
          src="../../../assets/images/partnersProgramm.png"
          className={styles.partnersProgram__image}
          alt={sectionData.alt}
          placeholder="none"
        />

      </section>
    </SectionContainer>
  )
}
