import { externalLinks } from "../../pages-data/common/links"

export default {
	header: "Отзывы наших партнёров",
	partners: [
		{
			img: "savchenko",
			alt: "Дмитрий Савченко",
			link: externalLinks.quickrestoBY,
			title:
				"Дмитрий Савченко,\n директор «Трип кип» —\n эксклюзивный представитель\n Quick Resto в Республике Беларусь",
			text: [
				'"Вместе с Quick Resto мы быстро закрыли проблемы малых и средних заведений общественного питания Беларуси в автоматизации.',
				{
					mobile:
						"Модель сотрудничества, которую предлагает Quick Resto, максимально упрощает приобретение, установку и внедрение системы автоматизации конечными пользователями, что позволяет нам больше внимания уделять процессу продаж.",
				},
				{
					mobile:
						'Мы убедились в надёжности компании и высоком уровне технической подготовки её специалистов. А наши клиенты ценят постоянное развитие сервиса и выпуск новых продуктов."',
				},
			],
		},
		{
			img: "habarov",
			alt: "Денис Хабаров",
			link: externalLinks.quickrestoMSK,
			title: "Денис Хабаров,\n директор Kafeline,\n Москва",
			text: [
				{
					mobile:
						'"За 10 лет работы мы автоматизировали более 300 точек общепита по всей России.',
				},
				{
					mobile:
						"Система Quick Resto, с которой мы работаем уже три года, полностью закрывает потребности в автоматизации заведений в среднем бюджетном сегменте, при этом справляется с объектами со сложной внутренней структурой, например, франшизной сетью.",
				},
				'Формат нашей работы с компанией нас устраивает на 100%"',
			],
		},
		{
			img: "azimov",
			alt: "Аят Азимов",
			link: externalLinks.quickrestoKZ,
			title:
				"Аят Азимов,\n эксклюзивный представитель\n в Республике Казахстан",
			text: [
				{
					mobile:
						'"Мы с Quick Resto уже 5 лет: наша компания одна из первых представила рынку Казахстана облачную систему автоматизации. За эти годы у нас накопился большой положительный опыт сотрудничества.',
				},
				{
					mobile:
						"Гибкая политика цен, качественный сервис, понятный и удобный интерфейс и масштабируемость — вот, что выделяет систему Quick Resto среди прочих других.",
				},
				'Мы высоко ценим наше сотрудничество с компанией Quick Resto и строим большие планы на будущее."',
			],
		},
		{
			img: "radjabov",
			alt: "Ахмед Раджабов",
			link: externalLinks.quickrestoDG,
			title:
				"Ахмед Раджабов,\n представитель Quick Resto\n в Республике Дагестан",
			text: [
				{ mobile: '"С компанией Quick Resto мы успешно работаем уже 5 лет.' },
				{
					mobile:
						"Это стало возможным благодаря внимательному отношению сотрудников компании к своим партнёрам.",
				},
				{
					mobile:
						"Отметим профессионализм, компетентность и оперативность специалистов технической поддержки.",
				},
				{
					mobile:
						'С большим удовольствием будем работать с Quick Resto в будущем."',
				},
			],
		},
	],
}
