import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'
import CheckList from '../../CheckList'

import sectionData from '../../../pages-data/partners/earnMore'

import styles from './styles.module.styl'


export default function SectionPartnersEarnMore() {
  return (
        <SectionContainer className={styles.earnMore__sectionContainer}>
          <section className={styles.earnMore}>

            <h2 className={`${styles.earnMore__header} ${styles.mobile}`}>
              {sectionData.header}
            </h2>

            <StaticImage
              src='../../../assets/images/earnmore.png'
              className={styles.earnMore__image}
              alt={sectionData.alt}
            />

            <div className={styles.earnMore__textColumn}>
                <h2 className={styles.earnMore__header}>
                  {sectionData.header}
                </h2>

                <p className={styles.earnMore__description}>
                  {sectionData.description}
                </p>

                <p className={styles.earnMore__description}>
                  {sectionData.remark}
                </p>

                <CheckList
                  liClassName={styles.earnMore__feature}
                  data={sectionData.features}
                />
            </div>

          </section>
        </SectionContainer>
      )
}
