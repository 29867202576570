import commonData from "../common/commonData"

export default {
	inputAriaLablel: "Телефон",
	header: "Как стать партнёром Quick Resto",
	content: "Оставьте заявку, и наш менеджер расскажет все детали.",
	button: "Оставить заявку",
	placeholder: commonData.phoneMask,
	notification: "Мы вам позвоним!",
	invalidMessage:
		"Ошибка на сервере! Пожалуйста, позвоните нам: <a href='tel:88005002138'>8 800 500-21-38</a> (звонок бесплатный)",
	id: "kak-stat-partnerom-quick-resto",
}
