export default {
	header: "Что мы предлагаем нашим партнерам",
	description: "Вы получаете‌ все необходимое для работы:",
	alt: "Партнёрская программа Quick Resto - фотография 3",
	features: [
		"Рекламные материалы для рассылок",
		"Обучение для вас и ваших представителей",
		"Техническую поддержку ваших клиентов 24/7",
		"Личного куратора",
	],
}
