import React from "react"

import OldVersionLayout from "../layout/oldVersion"

import {
	requestForHowToBecomePartner,
	requestPartnersRevenue,
} from "../apiRequests/callToAction"

import SectionPartnersProgram from "../components/sections/SectionPartnersProgram"
import SectionPartnersEasyFind from "../components/sections/SectionPartnersEasyFind"
import SectionPhoneCallRequest from "../components/sections/SectionPhoneCallRequest"
import SectionPartnersOffer from "../components/sections/SectionPartnersOffer"
import SectionPartnersPassiveRevenue from "../components/sections/SectionPartnersPassiveRevenue"
import SectionEmailRequest from "../components/sections/SectionEmailRequest"
import SectionPartnersEarnMore from "../components/sections/SectionPartnersEarnMore"
import SectionPartnersReviews from "../components/sections/SectionPartnersReviews"

import phoneCallSectionData from "../pages-data/partners/howToBecomePartner"
import emailRequestSectionData from "../pages-data/partners/howMuchWillYouEarn"

import styles from "./styles/partners.module.styl"

import partnersProgramm from "../assets/images/partnersProgramm.png"
import complexOfferCTAData from "../pages-data/index/evaluate"
import Breadcrumbs from "../components/Breadcrumbs"

export default function PagePartners() {
	const metaTags = [
		{
			name: "viewport",
			content: "width=device-width, initial-scale=1, shrink-to-fit=no",
		},
		{
			"http-equiv": "Content-Type",
			content: "text/html; charset=UTF-8",
		},
		{
			"http-equiv": "X-UA-Compatible",
			content: "ie=edge",
		},
		{
			name: "description",
			content:
				"Партнерская программа Quick Resto — новые возможности для вашего бизнеса. Станьте партнером Quick Resto, привлекайте новых клиентов и зарабатывайте вместе с нами. Получите рекламные материалы для рассылок, личного куратора и техническую поддержку 24/7. Выплачиваем вознаграждение до 100% от счета клиента.",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/partners/",
		},
		{
			property: "og:description",
			content:
				"Партнерская программа Quick Resto — новые возможности для вашего бизнеса. Станьте партнером Quick Resto, привлекайте новых клиентов и зарабатывайте вместе с нами. Получите рекламные материалы для рассылок, личного куратора и техническую поддержку 24/7. Выплачиваем вознаграждение до 100% от счета клиента.",
		},
		{
			property: "og:title",
			content: "Партнерская программа Quick Resto — новые возможности бизнеса",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content: `https://quickresto.ru${partnersProgramm}`,
		},
	]

	return (
		<OldVersionLayout
			title="Партнерская программа Quick Resto — новые возможности бизнеса"
			metaTags={metaTags}
			linkCanonical="https://quickresto.ru/partners/"
		>
			<Breadcrumbs />

			<SectionPartnersProgram />

			<SectionPartnersEasyFind />

			<SectionPhoneCallRequest
				data={phoneCallSectionData}
				ctaAction={requestForHowToBecomePartner}
				sectionClassName={styles.howToBecomePartner}
				headerClassName={styles.howToBecomePartner__header}
				textClassName={styles.howToBecomePartner__text}
			/>

			<SectionPartnersOffer />

			<SectionPartnersPassiveRevenue />

			<SectionEmailRequest
				data={emailRequestSectionData}
				ctaAction={requestPartnersRevenue}
				className={styles.howMuchWillYouEarn__header}
			/>

			<SectionPartnersEarnMore />

			<SectionPartnersReviews />

			<SectionPhoneCallRequest
				data={{
					...complexOfferCTAData,
					header: "Станьте партнером Quick Resto уже сейчас:",
				}}
				ctaAction={requestForHowToBecomePartner}
				sectionContainerClassName={styles.becomePartner}
				headerClassName={styles.becomePartner__header}
				horizontal
			/>
		</OldVersionLayout>
	)
}
