export default {
	header: "Легко найти — легко заработать",
	description:
		"Каждый месяц в России открывается более 2 000 новых точек общепита.\n Станьте партнёром Quick Resto, привлекайте новых клиентов и зарабатывайте вместе с нами.",
	subtitle: "Не требует вложений.\nСтатус партнёра может получить каждый:",
	alt: "Партнёрская программа Quick Resto - фотография 2",
	partnersList: [
		"Дилер кассового оборудования",
		"Бизнес-консультант",
		"Ресторанный маркетолог или бухгалтер",
		"Владелец точки общепита",
	],
	remark:
		"Даже если у вас сейчас нет опыта в ресторанной сфере, вы тоже можете стать нашим партнёром.",
}
