import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from 'react-slick'

import SectionContainer from '../../SectionContainer'

import sectionData from '../../../pages-data/partners/passiveRevenue'

import styles from './styles.module.styl'



export default function SectionPartnersPassiveRevenue() {

        const sliderSetting = {
          dots: true,
          arrows: false,
          className: styles.passiveRevenue__slider,
          dotsClass: `slick-dots ${styles.passiveRevenue__dots}`
        }

        return (
          <SectionContainer className={styles.passiveRevenue__sectionContainer}>
            <section className={styles.passiveRevenue}>

              <h2 className={styles.passiveRevenue__header}>
                {sectionData.header}
              </h2>

              <p className=
                   {styles.passiveRevenue__textFirst}>
                {sectionData.textFirst}
              </p>

              <p className={styles.passiveRevenue__textSecond}>
                {sectionData.textSecond}
              </p>

              <StaticImage
                src='../../../assets/images/revenueChart.png'
                className={styles.passiveRevenue__image}
                alt={sectionData.alt}
              />

              {/*"Мобильный" слайдер*/}
              <Slider {...sliderSetting}>
                {
                  sectionData.revenueLevels.map(
                    level => (
                      <div
                        className={styles.passiveRevenue__sliderContent}
                        key={level.partnerLevel}
                      >

                        <p className={styles.passiveRevenue__partnerLevel}>
                          {level.partnerLevel}
                        </p>

                        <div className={styles.passiveRevenue__revenueWrapper}>

                          <div className={styles.passiveRevenue__percentageRevenue}>
                            {level.percentageRevenue}
                          </div>

                          <div className={styles.passiveRevenue__cashRevenue}>
                            {level.cashRevenue}
                          </div>

                        </div>

                      </div>
                    )
                  )
                }
              </Slider>

              <p className={styles.passiveRevenue__remark}>
                {sectionData.remark}
              </p>

            </section>
          </SectionContainer>
        )
}
