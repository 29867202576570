export default {
	header: "Зарабатывайте ещё больше",
	alt: "Партнёрская программа Quick Resto - фотография 4",
	description:
		"Продажа брендированного приложения\n для гостей. Ваше ежемесячное вознаграждение —\n от 20 до 40% от оплаты подписки.",
	remark: "Что ещё:",
	features: [
		"Продажа оборудования\n для старта",
		"Установка и настройка системы",
		"Обучение сотрудников\n заведения",
		"Заполнение номенклатуры,\n техкарт, ведение склада",
		"Аудит, бухгалтерия\n и прочие услуги",
	],
}
